<template>
	<FormCustomer
		:is-submitting="create.isCreating"
		@onConfirm="handleConfirm"
	/>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormCustomer from '@/components/FormCustomer.vue';

export default {
	name: 'CustomerCreate',
	components: {
		FormCustomer,
	},
	computed: {
		...mapState('customers', {
			create: 'create',
		}),
	},
	async created() {
		await Promise.all([
			this.getWarehouseList({ per_page: 'all' }),
			this.getCustomerGroupList({ per_page: 'all' }),
		]);
	},
	methods: {
		...mapActions({
			createCustomer: 'customers/createCustomer',
			getCustomerGroupList: 'customerGroups/getCustomerGroupList',
			getWarehouseList: 'warehouses/getWarehouseList',
		}),
		async handleConfirm(params) {
			await this.createCustomer(params);
		},
	},
};
</script>
